/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '@ctrl/ngx-emoji-mart/picker';
// @import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap')

// removido cierta propiedad css para performance
// @import "~driver.js/dist/driver.css";
@import "./app/utils/tutorial/driver.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html,
body {
    font-family: "Inter", sans-serif;
    max-width: 100vw;
    // max-height: 100vh;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #333;
}

app-root {
    max-width: 100%;
    // max-height: 100%;
}

:root {
    --black: #000;
    --dark: #555;
    --white: #fff;

    --main: white;
    --accent: #aaa;
    --white-child: #f0f0f0;

    --kuv-bg-options: var(--main);
    --kuv-cl-options: var(--dark);

    --kuv-bg-children: var(--white-child);
    --kuv-cl-children: var(--dark);

    --kuv-bg-hover: var(--accent);
    --kuv-cl-hover: var(--white);

    --kuv-bg-active: var(--accent);
    --kuv-cl-active: var(--white);

    --kuv-bg-logo: var(--white);
    // --kuv-cl-logo: var(--white);

    --kuv-bg-toggle: var(--dark);
    --kuv-cl-toggle: var(--white);

    // --kuv-cl-icon: var(--white);

    // --kuv-table-bg-body-1: white;
    // --kuv-table-bg-head: #343a40;
    // --kuv-table-cl-head: white;
    // --kuv-table-bg-body-2: white;

}

kuv-compact-sidebar .option.active {
    background-color: var(--accent) !important;
    border-color: var(--white);
}

kuv-compact-sidebar .option .text {
    color: #555 !important;
}

#kuv-sidebar {
    i {
        font-size: 20px !important;
    }

    .options-container {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
}

// #kuv-sidebar.toggled{
//     .logo-container{
//         padding: .75rem !important;
//     }
// }

// .k-cell.k-action-cell {
//     display: flex;

//     div:not(:last-child) {
//         margin-right: .5rem;
//     }
// }

// #ngx-kuv-sidebar-option-text {
//     line-height: 1.1;
// }

// div#kuv-sidebar .main-container .options-container .icon {
//     color: var(--kuv-cl-icon) !important;
// }

#filtros {
    .btn {
        border-color: #2563fd !important;
        background-color: #2563fd !important;
    }

    .btn:hover {
        background-color: #2563fd !important;
        border-color: #2563fd !important;
    }
}

.btn.btn-primary {
    border-color: #2563fd !important;
    background-color: #2563fd !important;
}

.btn.btn-primary:hover {
    background-color: #2563fd !important;
    border-color: #2563fd !important;
}

.btn.btn-primary.active,
.btn.btn-primary:active {
    background-color: #1a4ecf !important;
    border-color: #2563fd !important;
}

.ng-dropdown-panel {
    z-index: 100000 !important;
}

.label-form {
    color: #555555;
}

.campo-opcional {
    font-size: 12px;
    color: grey;
    font-style: italic;
    margin-left: 6px;
}

.modal-header {
    margin: 0px 8px;
}

.modal-body {
    margin: 8px;
}

.modal-title {
    font-size: 22px;
}

.modal-footer {
    margin: 0px 4px;
}

.btn-close {
    font-size: 12px;
}

.modal-version-update {
    .modal-content {
        background-color: #f7f7f7;
        border-radius: 30px;
        border: 0;
        border-bottom: 10px solid #c8c7f3;

        .modal-header {
            background: #2460f4;
            color: white;
            margin: 0;
            text-align: center;
            padding: 10px;

            .modal-title {
                font-weight: bolder;
                font-size: 24px;
                width: 100%;
                text-align: center;
            }
        }

        .modal-body {
            color: #333;

            h2 {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0;
            }

            h1 {
                font-weight: bold;
                font-size: 14px;
                width: 100%;
                text-align: center;
            }

            hr {
                border-top: 3px solid #d6d5f4;
                opacity: 1;
                margin: 5px 0px;
                border-radius: 1px;
            }
        }

        .modal-footer {
            display: flex;
            width: 100%;
            justify-content: center;
            margin: 0;
            border-top: 0;

            button {
                background-color: #2460f4;
                border-radius: 20px;
                min-width: 200px;
                font-weight: bolder;
            }
        }
    }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: #dc3545;
    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

ng-select .ng-dropdown-panel-items {
    min-height: 100px;
}

#banner-vencimiento {
    cursor: pointer;
}

.fc-button-primary {
    background-color: #2563fd!important;;
    border-color: #2563fd!important;
    color: white;
}

.fc-button-active {
    background-color: #1a4ecf!important;
    border-color: #1a4ecf!important;
    color: white;
}

.driver-popover-arrow {
    display: none;
}

.driver-popover .driver-popover-footer button {
    font-size: 14px;
}

.driver-popover .driver-popover-description {
    margin-top: 15px!important;
    margin-bottom: 22px;
}

full-calendar {
    a.fc-event{
        border: none;
        padding: .1rem .3rem;
        border-radius: 10px;
    }
}